
import router from './router';
import { asyncRouterMap } from "./config/router.js";
asyncRouterMap[0].children.forEach((item)=>{
  router.addRoute(item)
})
router.beforeEach(async (to, from, next) => {
  let getMToken = localStorage.getItem('token');
  if (getMToken || to.path === '/loginFree') {
    next();
  } else {
    next('/loginFree');
  }
});
