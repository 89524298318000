import Vue from 'vue';
import App from './App.vue';
import 'amfe-flexible';
import 'lib-flexible/flexible.js';
import Vant from 'vant';
import router from '@/router';
import 'vant/lib/index.less';
import './permission'
import { Toast } from 'vant';
const EventBus = new Vue();
Vue.prototype.$EventBus = EventBus;
Vue.use(Toast);
Vue.config.productionTip = false;
Vue.use(Vant);
new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');

