import Vue from "vue";
//路由
import VueRouter from "vue-router";
import { asyncRouterMap } from "@/config/router.js";
Vue.use(VueRouter);
const routes = []
  const router = new VueRouter({
    routes: routes.concat(asyncRouterMap),
    mode: "history",
  });
  export default router;