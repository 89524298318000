export const asyncRouterMap = [
  {
    path: '/alert',
    name: 'alert',
    component: () => import('@/components/alert.vue'),
    children: [
      {
        path: '/loginFree',
        name: 'login-free',
        component: () => import('@/views/loginFree/index.vue'),
        hidden: true,
        meta: { title: '' },
      },
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home/index.vue'),
        hidden: true,
        meta: { title: '首页' },
      },
      {
        path: '/pay',
        name: 'pay',
        component: () => import('@/views/pay/index.vue'),
        hidden: true,
        meta: { title: '支付页' },
      },
      {
        path: '/order',
        name: 'order',
        component: () => import('@/views/order/index.vue'),
        hidden: true,
        meta: { title: '订单' },
      },
      {
        path: '/orderDetails',
        name: 'order-details',
        component: () => import('@/views/orderDetails/index.vue'),
        hidden: true,
        meta: { title: '订单详情' },
      },
      {
        path: '/orderIogistics',
        name: 'order-iogistics',
        component: () => import('@/views/orderIogistics/index.vue'),
        hidden: true,
        meta: { title: '物流信息' },
      },
      {
        path: '/user',
        name: 'user',
        component: () => import('@/views/user/index.vue'),
        hidden: true,
        meta: { title: '个人信息' },
      },
    ],
    hidden: true,
    meta: { title: '' },
  },

  {
    path: '*',
    redirect: '/loginFree',
    hidden: true,
  },
];
